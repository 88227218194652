<template>
  <div id="Customer">
    <div class="banner text-center">
      <p class="wow slideInLeft">客户需求帮助我们打磨产品<br>共享共同进步</p>
    </div>

    <div class="container">
      <h1
        class="text-center"
        style="margin-bottom:20px;"
      >成立三年，超过150家客户使用了店驰</h1>
      <p
        class="text-center"
        style="font-size: 14px;    font-weight: normal;"
      >
        店驰「拓店组件」和「运营组件」致力于帮助连锁企业的拓展部和运营部选对位置，管好店铺。
      </p>
      <p
        class="text-center"
        style="font-size: 14px;    font-weight: normal;"
      >
        店驰成立三年以来，已经有超过150家企业客户正在使用店驰进行数字化赋能。
      </p>
    </div>

    <!-- 轮播图 -->
    <div
      id="swiper"
      class="container"
    >
      <div class="swiper-container banner-swiper">
        <div class="swiper-wrapper">
          <div
            v-for="(item,index) in swiperList"
            :key="index"
            class="swiper-slide"
          >
            <img
              class="swiper-lazy"
              :data-src="item.img"
            >
            <div class="swiper-lazy-preloader" />
            <div class="swiper-slide-title">
              <h1 v-html="item.title" />
              <p v-html="item.content" />
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination" />

        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev" />
        <div class="swiper-button-next" />
      </div>
    </div>

    <div class="container-fuild text-center">
      <a
        href="http://laidian360.mikecrm.com/d3wKrcp"
        class="btn btn-default btn-sm"
        style="background-color: #3d9be9; color: #fff; border:none; width: 141px; height: 36px; line-height: 2.3;"
      >
        加入他们
      </a>
    </div>

    <div
      class="container"
      style="
             margin-top: 20px;
             background: url(https://u528828-b52365d589a54e249ea26d08a73e3d5a.ktb.wqdian.net/qngroup001%2Fu528828%2F1%2F0%2Fa88e4c732cb845e99488711bc7989fef.png) rgba(47,47,47,0.59) no-repeat center center;
             background-blend-mode: multiply;
             background-size: cover;
"
    >
      <h4
        class="text-center"
        style="font-size:20px; color: #fff; line-height: 30px; font-weight: bold; margin: 20px 0;"
      >
        聚焦“三好”<br>
        好商品、好体验、好关系<br>
        其余的交给我们
      </h4>

      <p style="    color: rgb(255, 255, 255);    font-size: 18px;    padding: 0 25px;    line-height: 30px;    font-weight: normal;">
        “我们帮助行业将<strong>顶级零售知识、技能、经验、流程</strong>沉淀到我们的工具与服务中，让他们可以直接享受来店人的努力成果，我们直接为他们提供结果，帮助零售企业轻松的获得自己难以通过自己努力而达到的能力，让客户将精力聚焦在<strong>好商品、好体验、好关系上。</strong>让一亿零售人工作更轻松，生活更幸福。进而他们有更多的机会去造福全中国乃至全世界的每个人！&nbsp;”&nbsp;
        <br>
        &nbsp;
      </p>

      <p
        class="text-right"
        style="color:#fff; font-size: 16px"
      >——店驰创始人 邱浩</p>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import { WOW } from 'wowjs'

export default {
  name: 'Customer',
  data () {
    return {
      swiperList: [
        {
          img: require('@/assets/img/c_01.jpg'),
          path: '',
          title: '',
          content: ''
        },
        {
          img: require('@/assets/img/c_02.jpg'),
          path: '',
          title: '',
          content: ''
        },
        {
          img: require('@/assets/img/c_03.jpg'),
          path: '',
          title: '',
          content: ''
        },
        {
          img: require('@/assets/img/c_04.png'),
          path: '',
          title: '',
          content: ''
        }
      ]
    }
  },
  mounted () {
    /* banner-swiper */
    new Swiper('.banner-swiper', {
      loop: true, // 循环模式选项
      effect: 'fade',
      // 自动播放
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      // 延迟加载
      lazy: {
        loadPrevNext: true
      },
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true // 修改swiper的父元素时，自动初始化swiper
    })

    /* wowjs动画 */
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    })
    wow.init()
  }
}
</script>
<style scoped>
.banner {
  color: #fff;
  font-size: 30px;
  height: 180px;
  background: url(../assets/img/banner_3.jpg) rgba(70, 70, 70, 0.59) no-repeat
    center center;
  background-blend-mode: multiply;
  background-size: cover;
}

.banner > p {
  position: relative;
  top: 50px;
}

/* 轮播图 */
#swiper {
  height: 600px;
}

#swiper .banner-swiper {
  width: 100%;
  height: 100%;
}

#swiper .banner-swiper .swiper-slide img {
  width: 100%;
  height: 100%;
}

#swiper .banner-swiper .swiper-slide {
  position: relative;
}

#swiper .banner-swiper .swiper-slide-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  color: #fff;
  /*background: rgba(51, 51, 51, 0.534);*/
  text-align: center;
  line-height: 80px;
}

#swiper .banner-swiper .swiper-slide-title > h1 {
  font-size: 50px;
  margin-top: 12%;
}

#swiper .banner-swiper .swiper-slide-title > p {
  font-size: 20px;
  margin-top: 1%;
  font-weight: 700;
}

/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  .banner {
    height: 225px;
    line-height: normal;
  }

  .banner > p {
    font-size: 20px;
    top: 83px;
    position: relative;
    font-weight: bold;
  }

  #swiper {
    height: 200px;
  }
}
</style>
